@import "@/style/base";

.form {
  display: flex;
}

.fields {
  flex: 1 1 auto;
}

.operators {
  text-align: right;
  flex: 0 0 80px;
}

.row {
  display: flex;
  margin-bottom: 12px;
  gap: 12px;
}

.field {
  flex: 0 1 100%;
}

.field_span_2 {
  flex-shrink: 0.5;
}

.mobile_button {
  margin-right: 12px;
  margin-top: 12px;
  width: calc(50% - 6px);

  &:last-of-type {
    margin-right: 0;
  }
}

@media @mobile-screen {
  .form {
    flex-direction: column;
  }
}
