@import "@/style/base";

@prefix-w: 12px;
@prefix-icon-w: 24px;
@suffix-icon-w: 18px;

@gap: 4px;
@pad-l: 10px;
@pad-r: 4px;

.suffix_icon,
.prefix_icon,
.prefix {
  position: absolute;
  top: 1px;
  bottom: 1px;
}

.prefix {
  left: @pad-l;
  width: @prefix-w;
  color: var(--t-m);
  font-size: inherit;
}

.prefix ~ .input {
  padding-left: @pad-l + @prefix-w + @gap;
}

.prefix_icon {
  left: @pad-l;
  width: @prefix-icon-w;
  font-size: @prefix-icon-w;
  color: var(--t);
}

.suffix_icon {
  right: @pad-r;
  width: @suffix-icon-w;
  font-size: @suffix-icon-w;
  color: var(--t-m2);
  transition: 0.3s ease;
  cursor: pointer;

  &:hover {
    color: var(--t-m);
  }
}

.suffix_icon ~ .input {
  padding-right: @pad-r + @suffix-icon-w + @gap;
}

.prefix_icon ~ .input {
  padding-left: @pad-l + @prefix-icon-w + @gap;
}
