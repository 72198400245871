@import "@/style/base";

.popup {
  position: fixed;
  pointer-events: all;
  padding: 10px;
  border-radius: 2px;
  line-height: 20px;
  font-size: 14px;
  cursor: auto;
  transition-property: opacity;
  z-index: @popup-z-index;
  max-width: 100vw;
  box-sizing: border-box;

  &::before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-color: var(--b);
  }
}

@media @touch-screen {
  .popup_untouchable {
    display: none;
  }
}
