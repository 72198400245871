@import "@/style/base";

html {
  font-size: 14 / 12px;
  // overflow: hidden;
  // height: 100%;
}

body {
  font-size: 14px;
  // overflow: auto;
  // height: 100%;
  color: var(--t);
  background: var(--b);
}

body,
button,
input,
textarea {
  margin: 0;
  font-family: "main", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

a:-webkit-any-link {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
ul,
ol,
li,
figure,
dl {
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 14px;
}

button {
  border: none;
  background: none;
  cursor: pointer;
  color: inherit;

  &[disabled] {
    cursor: not-allowed;
  }
}

input {
  border: none;
}

ul,
ol {
  list-style: none;
}

abbr[title] {
  text-decoration: none;
}

// naming

// t: text
// b: background
// d: dark
// l: light
// m: mask
// red: red
// blue: blue
// -s: star
// -p: plus * n
// -m: minus * n

:root {
  // var
  --t-p: #000;
  --b-sp: #000; // currently same as t-p
  --t: #202020;
  --b-s: #333;
  --b-sm: #444;
  --t-m: #666;
  --t-m2: #999;
  --t-sm: #aaa;
  --t-m3: #bbb;
  --t-sm2: #c0c0c0;
  --e-p2: #ccc;
  --e-p: #ddd;
  --e: #eee;
  --e-m: #f4f4f4;
  --b-m: #f7f7f7;
  --b: #fff;
  --b-p: #fff;

  // var opacity colors
  // the definition of this part is different from
  // those opacity colors are for the replacing of fading colors
  --b-s-0: fade(#333, 0%);
  --b-s-10: fade(#333, 10%);
  --b-s-50: fade(#333, 50%);
  --b-0: fade(#fff, 0%);
  --b-50: fade(#fff, 50%);
  --b-90: fade(#fff, 90%);
  --b-sm-25: fade(#444, 25%);
  --t-m2-50: fade(#999, 50%);

  // const
  --d-p: #000;
  --d: #333;
  --d-m: #444;
  --d-m2: #666;
  --l-m: #999;
  --l-w: #bbb; // l weak
  --l: #ccc;
  --l-p: #fff;
  --red: #ff4741;
  --red-p: #e42012;
  --blue-m: #4193ef;
  --blue: #08f;

  // opacity
  --d-50: fade(#333, 50%);
  --d-80: fade(#333, 80%);
  --d-50: fade(#333, 50%);
  --l-p-80: fade(white, 80%);
  --m-0: fade(black, 0%);
  --m-10: fade(black, 10%);
  --m-20: fade(black, 20%);
  --m-25: fade(black, 25%);
  --m-45: fade(black, 45%);
  --m-60: fade(black, 60%);
  --m-85: fade(black, 85%);
  --w-0: fade(white, 0%);
  --w-80: fade(white, 80%);
}

@media @dark-mode {
  :root {
    // var
    --t-p: #fff;
    --b-sp: #fff;
    --t: #ccc;
    --b-s: #bbb;
    --b-sm: #aeaeae;
    --t-m: #979797;
    --t-m2: #797979;
    --t-sm: #6f6f6f;
    --t-m3: #646464;
    --t-sm2: #616161;
    --e-p2: #595959;
    --e-p: #4c4c4c;
    --e: #3b3b3b;
    --e-m: #343434;
    --b-m: #2f2f2f;
    --b: #1c1c1c;
    --b-p: #000;

    // opacity
    --b-s-0: fade(#bbb, 0%);
    --b-s-10: fade(#bbb, 10%);
    --b-s-50: fade(#bbb, 50%);
    --b-0: fade(#1c1c1c, 0%);
    --b-50: fade(#1c1c1c, 50%);
    --b-90: fade(#1c1c1c, 90%);
    --b-sm-25: fade(#aeaeae, 25%);
    --t-m2-50: fade(#797979, 50%);
  }
}

#root {
  position: relative;
}

@media print {
  #root,
  .overlay {
    display: none;
  }

  @page {
    margin: 0;
  }
}

@media @mobile-screen {
  font-size: 1px;
}
