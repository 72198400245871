.avatar {
  box-shadow: 0 0 0 0.5px var(--t-m2);
}

.avatar,
.avatar_img {
  border-radius: 50%;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
