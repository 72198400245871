@import "@/style/base";

.search_input {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  transition: 0.2s ease;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  background: none;
}

.input {
  display: block;
  width: 100%;
  background: none;
  border: none;
  outline: none;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 0 0 0 26px;
  box-sizing: border-box;

  &::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.6;
  }
}

.icon {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  position: absolute;
  left: 0;
  top: 0;
  cursor: text;
}

.icon--clickable {
  cursor: pointer;
}
