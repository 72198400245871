.cascader {
  position: relative;
  outline: none;
}

.input {
  cursor: pointer;
}

.placeholder,
.display {
  flex: 1 1 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.placeholder {
  color: var(--t-m2);
}

.icon {
  color: var(--t-m);
}

.options {
  display: flex;
  position: fixed;
  z-index: 1;
  box-sizing: 1px solid;
  list-style: none;
  box-shadow: 0 2px 7px var(--m-60);
  border-radius: 2px;
  margin: 0;
  background: var(--b);
  max-height: 33vh;
}

.layer-options {
  padding: 12px 0;
  border-right: 1px solid var(--e);
  min-width: 120px;
  // max-height: 33vh;
  overflow: auto;

  &:last-of-type {
    border-right: none;
  }
}

.option {
  display: flex;
  cursor: pointer;
  color: var(--t);
  background: none;
  padding: 12px 16px;

  &:hover:not(.option--selected):not(.option--empty) {
    background: var(--b-m);
  }
}

.option-text {
  flex: 1 1 0;
}

.option--selected {
  cursor: auto;
  color: var(--t-p);
  font-weight: 700;
}
