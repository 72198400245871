@import "@/style/base";

.review {
  color: var(--t-m);
  font-weight: 700;
}

// card
@size: 48px;
.user_banner {
  text-align: left;
  display: flex;
  gap: 16px;
  gap: 0 16px;
}

.avatar {
  height: @size;
}

.user_avatar {
  width: @size;
  height: @size;
}

.info {
  flex: 1 1 0;
  overflow: hidden;
}

.info_name_only {
  padding: @size / 4 0;
}

.avatar_clickable {
  cursor: pointer;
}

.name_row,
.info_row {
  line-height: @size / 2;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.review_icon {
  color: var(--t-m);
  font-size: 18px;
  margin-left: 20px;
}

.review,
.transaction {
  font-size: 14px;
}

.transaction {
  margin-left: 8px;
  color: var(--t-m);
}

.operation {
  align-self: center;
}

.follow_button {
  width: 120px;
}

.following,
.operation {
  margin-left: 20px;
}

.followers,
.following {
  line-height: @size;
}

.name {
  font-size: 16px;
  font-weight: 700;
  color: var(--t);
}

.verify_icon {
  font-size: 16px;
  margin-left: 4px;
}

.region {
  font-size: 12px;
  font-weight: 700;
  color: var(--t-m);
}

.dot {
  margin: 0 6px 0 5px;
  color: var(--t-m2);
}

.biography {
  font-size: 12px;
  color: var(--t-m2);
}

.follow_count {
  margin-right: 4px;
  font-size: 14px;
  font-weight: 700;
  color: var(--t-m);
}

.follow_text {
  font-size: 14px;
  color: var(--t-m2);
}

.more_button {
  font-size: 24px;
}

@media @xs-screen {
  .following,
  .operation {
    margin-left: 10px;
  }

  .review_icon {
    margin-left: 10px;
  }
}

@media @mobile-screen {
  @header-h: 32px;
  @t: 0.2s ease-out;

  .header {
    text-align: center;
    position: relative;
  }

  .name {
    line-height: @header-h;
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 28px;
  }

  .name_self {
    padding: 0 80px 0 0;
    text-align: left;
    font-weight: 700;
  }

  .verify_icon {
    font-weight: 500;
  }

  .more_button,
  .menu_button,
  .more_button_follow,
  .message_link {
    position: absolute;
    right: 0;
    top: 0;
    font-size: @header-h * 0.875;
    line-height: @header-h;
    height: @header-h;
    color: var(--t);
  }

  .more_button_follow {
    @h: 28px;
    width: 60px;
    font-size: 12px;
    line-height: @h;
    height: @h;
    margin-top: (@header-h - @h) / 2;
  }

  .message_link {
    right: 44px;

    &.message_link_notified {
      @s: 4px;
      &:after {
        content: "";
        display: block;
        width: @s;
        height: @s;
        background: red;
        position: absolute;
        bottom: -2px;
        left: 14px - @s / 2;
        border-radius: 50%;
      }
    }
  }

  @s: 72px;
  .avatar_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: @s;
    margin: 0 0 12px;
    transition: @t;
  }

  .avatar {
    position: relative;
    top: 0;
    height: @s;
    transition: @t;
  }

  .user_avatar {
    width: @s;
    height: @s;
    flex: 0 0 @s;
    transition: @t;
  }

  .following,
  .followers {
    text-align: center;
  }

  .follow_count,
  .follow_text {
    display: block;
    color: var(--t);
  }

  .follow_count {
    font-size: 16px;
    line-height: 20px;
    margin: 0;
  }

  .follow_text {
    font-size: 12px;
    line-height: 14px;
  }

  .region_row {
    line-height: 24px;
  }

  .region {
    font-size: 14px;
    color: var(--t);
  }

  .bio {
    font-size: 12px;
  }

  .operation {
    margin: 14px 0;

    & > * {
      width: 100%;
    }
  }

  .minor_info {
    max-height: 100%;
    opacity: 1;
    transition: @t;
    overflow: hidden;
  }

  .short {
    @s: 36px;
    .avatar_row {
      height: 0;
      margin: 0;
    }

    .avatar {
      height: @s;
      top: -16px;
    }

    .user_avatar {
      width: @s;
      height: @s;
      flex: 0 0 @s;
    }

    .minor_info {
      max-height: 0;
      opacity: 0;
    }

    .following,
    .followers {
      display: none;
    }

    .name {
      padding: 0 64px;
      font-size: 14px;
    }

    .name_self {
      padding: 0 80px;
    }
  }

  .as_list_item {
    .name,
    .name_self {
      padding: 0 90px 0 44px;
      text-align: left;
    }

    .more_button_follow {
      width: 80px;
    }
  }
}
