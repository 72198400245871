@import "@/style/base";

.card {
  position: relative;
}

.card_backgrounded,
.card_empty {
  padding: 6px 8px 8px 38px;
  background: var(--b-m);
  border-radius: 2px;
}

.header {
  display: flex;
  align-items: flex-start;
  min-height: 32px;
}

.name {
  .multi-line-text;
  font-size: 14px;
  margin-right: 14px;
  flex: 0 1 auto;
  font-weight: 700;
}

.phone {
  font-size: 14px;
  color: var(--t-m);
}

.name,
.phone {
  line-height: 18px;
  margin-top: 8px;
}

.tag {
  margin-top: 9px;
  margin-left: 8px;

  &:first-of-type {
    margin-left: 14px;
  }
}

.tag_disabled {
  color: var(--t);
  background: var(--e-p);
}

.link_btn {
  flex: 0 0 auto;
  display: inline-block;
  @size: 32px;
  width: @size;
  height: @size;
  line-height: @size;
  padding: 0;
  margin-left: 4px;
  font-size: 20px;
  color: var(--t-m);
  transition: color 0.2s ease;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    color: var(--t);
    background: var(--b-m);
  }
}

.zip_code,
.address,
.country,
.loading_row {
  font-size: 14px;
  margin-top: 6px;
  color: var(--t-m);
  line-height: 18px;
}

.loading_row {
  color: var(--t-m2);
}

// form
.name_input {
  width: 160px;
  margin-right: 10px;
}

.phone_input {
  width: 200px;
  margin-left: 20px;
}

.address_input,
.country_input,
.state_input,
.city_input,
.zip_code_input {
  margin-top: 10px;
}

.zip_code_input,
.country_input,
.state_input,
.city_input {
  width: 300px;
}

.address_input {
  width: 500px;
}

.deletion_footer {
  display: flex;
}

.button_icon {
  margin-right: 10px;
}

.empty_title {
  color: var(--t-m);
  font-size: 14px;
  margin-top: 6px;
}

.region_list {
  margin-top: 14px;
}

.region {
  color: var(--t-m);
  font-size: 14px;
  line-height: 20px;
}

.empty_link_row {
  margin: 14px 0 8px;
}

.create_link {
  color: var(--t);
  font-size: 14px;
  text-decoration: underline;
  font-style: italic;

  &:hover {
    color: var(--t-p);
  }
}

.card_disabled {
  .name,
  .phone,
  .tag,
  .address,
  .zip_code,
  .country {
    opacity: 0.5;
  }
}

.location_icon {
  font-size: 24px;
  color: var(--t);
  position: absolute;
  top: 11px;
  left: 6px;
}

@media @mobile-screen {
  .header {
    flex-wrap: wrap;
  }

  .name {
    font-size: 14px;
    margin-right: 10px;
  }

  .tag {
    margin-left: 10px;
  }
}

@media @fold-screen {
  .location_icon {
    display: none;
  }

  .card {
    padding: 6px 8px 8px;
  }
}
