.hint {
  position: absolute;
  color: var(--e-p);
  text-align: center;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

.hint_hidden {
  display: none;
}

.background-mode-img {
  background-size: contain;
}

.picture_zoomable {
  cursor: zoom-in;
}

.no_image {
  text-align: center;
  font-size: 14px;
  color: var(--t-m2);
}

.img {
  display: block;
  transition: opacity 0.3s ease;
  width: 100%;
}

.img_transitive {
  opacity: 0;
}

.img_loaded.img_transitive {
  opacity: 1;
}

.img_error {
  display: none;
  background: var(--b);
}

.zoom_close {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  @size: 40px;
  width: @size;
  height: @size;
  @gap: 16px;
  top: @gap;
  right: @gap;
  cursor: pointer;
  transform: scale(1);
  color: var(--l);
  transition: transform 0.1s ease;
  background: var(--d-80);
  border-radius: 50%;

  &:hover {
    transform: scale(1.1);
  }

  .close_icon {
    font-size: 30px;
    transition: inherit;
  }
}

.zoom_img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: var(--d);
}

.controller {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  @w: 160px;
  width: @w;
  left: calc(50% - @w / 2);
  bottom: 20px;
  height: 40px;
  transition: background 0.2s;
  border-radius: 4px;
  background: var(--d-50);

  &:hover {
    background: var(--d-80);
  }
}

.controller_prev,
.controller_next {
  font-size: 30px;
  color: var(--l);

  &[disabled] {
    color: var(--d-m2);
  }
}

.controller_text {
  color: var(--l);
  font-size: 20px;
  flex: 0 0 50%;
  text-align: center;
}

// picture styles
.picture {
  display: block;
  overflow: hidden;
}

.picture_auto .img {
  height: auto;
}

.picture_fill .img {
  height: 100%;
}

.picture_contain .img {
  height: 100%;
  object-fit: contain;
}

.picture_cover .img {
  height: 100%;
  object-fit: cover;
}
