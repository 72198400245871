@import "@/style/base";

.dots {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.dot-size(@outer-size, @inner-size) {
  @delta: (@outer-size - @inner-size) / 2;
  width: @outer-size;
  height: @outer-size;

  &::after {
    top: @delta;
    left: @delta;
    width: @inner-size;
    height: @inner-size;
  }
}

.dot {
  position: relative;
  cursor: pointer;
  transition: 0.5s ease;
  .dot-size(16px, 6px);

  &::after {
    content: "";
    position: absolute;
    border-radius: 50%;
    transform: scale(1);
    transition: transform 0.5s ease;
    background: var(--m-20);
  }

  &.dot_small::after {
    transform: scale(0.8);
  }

  &.dot_smaller::after {
    transform: scale(0.3);
  }

  &.dot_invisible {
    width: 0;

    &::after {
      transform: scale(0);
    }
  }

  &.dot_interactable:hover::after {
    background: var(--m-45);
  }

  &.dot_active::after,
  &.dot_interactable.dot_active:hover::after {
    background: var(--m-85);
  }
}

@media @mobile-screen {
  .dot {
    .dot-size(12px, 6px);
  }
}
