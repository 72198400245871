.switch {
  display: inline-block;
  border: none;
  padding: 0;
  background: var(--b-sm-25);
  cursor: pointer;
  transition: 0.2s ease;
  overflow: hidden;
}

.switch--on {
  background: var(--b-sm);
}

.toggle {
  border-radius: 50%;
  background: var(--l-p);
  transition: 0.2s ease;
  box-shadow: 0 0 10px var(--m-10);
}

.size-style(@h, @d) {
  @w: (@h - @d * 2) * 2;

  &.switch {
    height: @h;
    width: @w;
    border-radius: @h / 2;
  }

  .toggle {
    @inner-h: @h - @d * 2;
    height: @inner-h;
    width: @inner-h;
    transform: translate(@d, @d);
  }

  .toggle--on {
    transform: translate(@w - @h + @d, @d);
  }
}

.switch_small {
  .size-style(14px, 1px);
}

.switch_mid {
  .size-style(18px, 1.5px);
}

.switch_large {
  .size-style(36px, 2px);
}
