.visible {
  visibility: visible;
  transition-property: opacity;
  opacity: 1;
}

.invisible {
  visibility: hidden;
  transition: none;
  opacity: 0;
}
