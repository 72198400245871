@import "@/style/base";

.button {
  transition: 140ms ease;
  border-radius: @form-border-radius;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  justify-content: center;
  align-items: center;

  &:focus {
    outline: 2px solid var(--e-p);
  }
}

.button_block {
  display: flex;
  width: 100%;
}

.button_inline {
  display: inline-flex;
}

.button_mid {
  line-height: 44px;
  font-size: 18px;
  padding: 0 12px;
}

.button_small {
  line-height: 32px;
  font-size: 14px;
  padding: 0 8px;
}

.button_large {
  line-height: 48px;
  font-size: 22px;
  padding: 0 14px;
}

.type-style(@normal-color, @highlight-color, @text-color) {
  &.button_disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.button_fill {
    background: @normal-color;
    border-color: @normal-color;
    color: @text-color;

    &:hover:not(.button_disabled) {
      background: @highlight-color;
      border-color: @highlight-color;
    }
  }

  &.button_hollow {
    background: transparent;
    border-color: @normal-color;
    color: @normal-color;

    &:hover:not(.button_disabled) {
      border-color: @highlight-color;
      color: @highlight-color;
    }
  }
}

.button_black {
  .type-style(var(--b-s), var(--b-sp), var(--b));
}

.button_white {
  .type-style(var(--e-p2), var(--e), var(--t));
}

.button_danger {
  .type-style(var(--red), var(--red-p), var(--b));
}

.button_loading {
  opacity: 0.6;
  cursor: progress;
}

.button_icon.button_icon {
  margin: 0 0.3em;
  font-size: 1em;
}

// button group
.button_group {
  display: flex;
}

.button_group_inline {
  display: inline-flex;
}

.button_ingroup {
  display: block;
  flex: 1 1 auto;
  margin-left: 0;

  &:not(:first-of-type) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-left: none;
  }

  &:not(:last-of-type) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

@media @xs-screen {
  .button_mid {
    font-size: 16px;
    padding: 0 8px;
  }

  .button_small {
    font-size: 14px;
    padding: 0 6px;
  }

  .button_large {
    font-size: 18px;
    padding: 0 10px;
  }
}

@media @mobile-screen {
  .button_small {
    line-height: 30px;
    font-size: 12px;
  }
}
