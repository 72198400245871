.table {
  width: 100%;
  border-collapse: collapse;
}

.th,
.td {
  padding: 5px 10px;
}

.tr--body {
  border-bottom: 1px solid var(--e-m);

  &:last-of-type {
    border-bottom: none;
  }
}
