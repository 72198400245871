@s: 30px;

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page,
.button,
.more {
  text-align: center;
  height: @s;
  line-height: @s;
  width: @s;
  cursor: pointer;
  padding: 0;
  background: none;
  border: none;
  color: var(--t);
  font-size: 14px;
  border-radius: 2px;
}

.more {
  font-size: 16px;
}

.button,
.more {
  color: var(--t-m2);

  &:hover {
    color: var(--t-m);
    background: var(--e);
  }
}

.page {
  &:hover {
    background: var(--e);
  }
}

.page_selected {
  cursor: auto;
  background: var(--b-s);
  color: var(--b-p);

  &:hover {
    background: var(--b-s);
  }
}

.button_disabled {
  color: var(--e-p2);
  cursor: not-allowed;

  &:hover {
    background: none;
  }
}
