.img {
  @size: 16px;
  width: @size;
  height: @size;
  margin-right: 6px;
  vertical-align: middle;
}

.text {
  vertical-align: middle;
}
