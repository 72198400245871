// atomic class
@import "./base.less";

.opaque {
  opacity: 1;
}

.transparent {
  opacity: 0;
}

.no-scrollbar {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.eventless {
  pointer-events: none;
}

.eventful {
  pointer-events: all;
}

.mask {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

// single form styles
.single_form {
  width: 400px;
  max-width: 100vw;
  padding-top: 10vh;
  margin: 0 auto 30px;
  height: 100%;
  box-sizing: border-box;

  .row {
    margin-bottom: 16px;
  }

  .title {
    .row;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
  }

  .instr {
    .row;
    font-size: 16px;
    line-height: 22px;
    color: var(--t-m);
  }

  .footer {
    margin-top: 30px;
  }
}

.spaceholder {
  flex: 1 1 10px;
}

.mobile_display {
  display: none;
}

.ensemble_content {
  .doc-content;
  max-width: 800px;
}

@media @mobile-screen {
  .single_form {
    width: 375px - 18px * 2;
  }

  .mobile_none {
    display: none;
  }

  .mobile_display {
    display: block;
  }
}

@media @lg-screen {
  .ensemble_content {
    max-width: 1000px;
  }
}

@media @mobile-screen {
  .ensemble_content {
    width: 100%;
  }
}
