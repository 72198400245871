.modal {
  width: 700px;
}

.address_list {
  overflow: auto;
  // height: 60vh;
}

.available_region_title {
  color: var(--t-m);
  margin-bottom: 10px;
  font-size: 14px;
}

.region_list {
  margin-bottom: 10px;
}

.region {
  color: var(--t-m);
  font-size: 14px;
  line-height: 20px;
}
