.error_alert {
  display: flex;
  align-items: flex-start;
  background: var(--b-sm);
  color: var(--b);
  gap: 10px;
  padding: 10px 20px;
  border-radius: 4px;
}

.error_content {
  color: inherit;
  word-break: break-all;
  line-height: 24px;
}

.error_icon {
  font-size: 24px;
  height: 24px;
  color: inherit;
}

.retry_button {
  color: inherit;
  margin-top: 8px;
  text-decoration: underline;
}

.success_icon_row,
.success_title,
.success_content {
  text-align: center;
  margin-bottom: 16px;
}

.success_icon {
  color: var(--t);
  font-size: 40px;
  display: block;
  text-align: center;
}

.success_title {
  color: var(--t);
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
}

.success_content {
  color: var(--t);
  font-size: 16px;
}
