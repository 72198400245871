@h: 25px;

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 40px;
  border-radius: 50px;
  line-height: @h;
  cursor: pointer;
  background: var(--e);
  transition: background-color 0.2s;
  width: 100%;
  box-sizing: border-box;
}

.text {
  font-style: italic;
  font-size: 18px;
  line-height: @h;
  color: var(--t);
  margin-left: 8px;
  font-weight: 900;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.img {
  height: @h;
}

.instr {
  font-size: 18px;
  margin: 20px 0;
  color: var(--t-m2);
  text-align: center;
  font-style: italic;
}
