@star-size: 24px;

.wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.stars_interactable {
  cursor: pointer;
}

.star {
  display: inline-block;
  height: @star-size;
  width: 22px;
  text-align: center;
  color: var(--t-m2);
  cursor: inherit;
}

.star_lighted {
  color: var(--t);
}

.star_icon {
  height: @star-size;
  line-height: @star-size;
  font-size: 22px;
}

.star_value {
  line-height: @star-size;
  color: var(--t-m);
  font-size: 16px;
}
