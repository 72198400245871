// animations

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes bg-highlight {
  0% {
    background: var(--e);
  }

  66% {
    background: var(--e);
  }

  100% {
    background: var(--b);
  }
}
