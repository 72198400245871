// checkable styles
.checkable_wrapper {
  line-height: 20px;
  padding: 4px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkable_wrapper_disabled {
  cursor: not-allowed;
}

.checkable {
  flex: 0 0 auto;
  position: relative;
  background: var(--e);
  margin-right: 15px;

  &::before {
    content: "";
    position: absolute;
    background: var(--b-s-0);
  }
}

.checkable_size(@size) {
  @inner-size: 0.5 * @size;

  &.checkable {
    width: @size;
    height: @size;

    &:before {
      top: (@size - @inner-size) / 2;
      left: (@size - @inner-size) / 2;
      width: @inner-size;
      height: @inner-size;
    }
  }
}

.checkable_mid {
  .checkable_size(14px);
}

.checkable_large {
  .checkable_size(16px);
}

.checkable_checked::before {
  background: var(--b-s);
}

.checkable_radio {
  border-radius: 50%;

  &::before {
    border-radius: 50%;
  }
}

.label {
  flex: 1 1 0;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  cursor: inherit;
}

.checkable_input {
  position: absolute;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.checkable_input_disabled {
  cursor: not-allowed;
}
