@import "@/style/base";

.form_item {
  display: grid;
  margin-bottom: 12px;
}

.label {
  grid-area: label;
  font-weight: 500;
  color: var(--t);
  font-size: 14px;
  align-self: flex-start;
}

.form_item_horizontal {
  grid-template: "label content" auto "label alert" auto / 114px auto;
  column-gap: 6px;

  .label {
    line-height: @form-line-height;
  }
}

.form_item_vertical {
  grid-template: "label" auto "content" auto "alert" auto / auto;

  .label {
    line-height: 26px;
  }
}

.form-content {
  grid-area: content;
}

.form-alert {
  grid-area: alert;
  color: var(--red);
  font-size: 12px;
  margin-top: 5px;
}

.text-content {
  line-height: @form-line-height - 2px;
  color: var(--t-m); // CHECK:
  font-size: 16px;
}

.require_symbol {
  color: inherit;
  font-style: normal;
  margin-left: 1px;
}

@media @xs-screen {
  .form_item_horizontal:not(.form_item_horizontal_always) {
    .form_item_vertical;
  }

  .label {
    font-size: 12px;
  }
}
