.action_sheet {
  width: 240px;
}

.title {
  font-weight: 700;
  font-size: 14px;
}

.instr {
  margin: 18px 0;
  font-size: 14px;
  line-height: 18px;
}
