@import "@/style/base";

@label-w: 18px;
@gap: 4px;
@pad-l: 10px;
@pad-r: 4px;

.label {
  position: absolute;
  top: 0;
  bottom: 0;
  right: @pad-r;
  width: @label-w;
  font-size: @label-w;
  color: var(--t-m2);
  transition: 0.3s ease;
  cursor: pointer;

  &:hover {
    color: var(--t-m);
  }
}

.label + .input {
  padding-right: @pad-r + @label-w + @gap;
}
