// stylelint-disable
@import "@/style/base";

.tabs {
  display: flex;
  border-bottom: 1px solid transparent;
  margin-bottom: 16px;
}

.tab_wrapper {
  flex: 0 0 auto;
  cursor: pointer;
}

.tab {
  position: relative;
  padding: 1px 10px 0;
  font-weight: 700;
  border-bottom: 4px solid transparent;
  transition: 0.3s ease;
  transition-property: border-color color;
  text-align: center;
}

.tab_mid {
  font-size: 16px;
  line-height: 26px;
}

.tab_large {
  font-size: 18px;
  line-height: 30px;
}

.tab_wrapper_active {
  cursor: auto;
}

.tabs_light {
  border-bottom-color: var(--e);

  .tab {
    color: var(--t-m2);
    border-bottom-color: var(--b-s-0);
  }

  .tab_wrapper_active {
    .tab {
      color: var(--t-p);
      border-color: var(--b-s);
    }
  }
}

.tabs_dark {
  border-bottom-color: var(--d-m2);

  .tab {
    color: var(--l-m);
    border-bottom-color: transparent;
  }

  .tab_wrapper_active {
    .tab {
      color: var(--l-p);
      border-color: var(--l-p);
    }
  }
}

@media @mobile-screen {
  .tab {
    border-bottom-width: 2px;
    line-height: 32px;
  }
}
