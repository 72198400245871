.display_price_wrapper {
  font-size: 0.9em;
  line-height: 1.5em;
  margin-bottom: 0.5em;
}

.original_price,
.display_price {
  font-weight: 700;
  color: inherit;
}

.approx {
  margin-right: 0.5em;
}

.shipping_text {
  opacity: 0.5;
  margin-left: 0.5em;
  font-size: 0.9em;
}

.inline_wrapper {
  display: inline;
  vertical-align: baseline;
  margin: 0;

  .original_price,
  .display_price {
    font-weight: 500;
  }
}
