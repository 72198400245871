// normal select styles
// stylelint-disable

// LATER: refactor

@import "@/style/base";

.select {
  position: relative;
  outline: none;

  .placeholder,
  .display {
    flex: 1 1 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .display_no_grow {
    flex-grow: 0;
  }

  .display_no_width {
    flex-basis: 0;
  }

  .placeholder {
    color: var(--e-p2);
    pointer-events: none;
  }

  .input {
    cursor: pointer;
  }

  .options {
    position: fixed;
    z-index: 1;
    padding: 12px 0;
    box-sizing: 1px solid;
    list-style: none;
    box-shadow: 0 2px 7px var(--m-60);
    border-radius: 2px;
    margin: 0;
    background: var(--b);
    max-height: 33vh;
    overflow: auto;
  }

  .option {
    display: flex;
    cursor: pointer;
    color: var(--t);
    background: none;
    line-height: 32px;
    padding: 4px 16px;
  }

  .option--hoverable:hover,
  .option--pointed {
    background: var(--b-m);
  }

  .option--selected {
    cursor: auto;
    color: var(--t-p);
    font-weight: 700;
  }

  .option--empty {
    cursor: auto;
    &:hover {
      background: inherit;
    }
  }

  .option--multi-selected {
    cursor: pointer;
  }

  .option-text {
    flex: 1 1 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .option-icon {
    margin-left: 10px;
    flex: 0 0 auto;
  }

  .tag {
    display: inline-flex;
    background: var(--e);
    border-radius: 2px;
    line-height: 16px;
    padding: 2px 4px;
    margin-right: 10px;
  }

  .tag-text {
    white-space: nowrap;
    margin-right: 0.5em;
    font-size: 12px;
  }

  .tag-icon {
    font-size: 12px;
  }
}

.select_disabled {
  cursor: not-allowed;

  * {
    pointer-events: none;
  }
}

.dropdown_select {
  position: relative;
  outline: none;

  .display {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .text_wrapper {
    flex: 1;
    white-space: nowrap;
    color: var(--t);
  }

  .icon {
    color: var(--t-m);
  }

  .display_large {
    .text_wrapper {
      font-size: 20px;
    }

    .icon {
      font-size: 18px;
      margin-left: 8px;
    }
  }

  .display_mid {
    .text_wrapper {
      font-size: 14px;
    }

    .icon {
      font-size: 14px;
      margin-left: 6px;
    }
  }
}

// inline dropdown
.dropdown_select_inline {
  display: inline-block;

  .display {
    display: inline-flex;
  }
}

.dropdown_options {
  &.options {
    position: absolute;
    z-index: 1;
    padding: 16px;
    box-sizing: border-box;
    top: 100%;
    background: var(--b);
    box-shadow: 0 2px 7px var(--m-20);
    border-radius: 2px;
  }

  &.options_left_aligned {
    left: 0;
    transform: translate(0, 10px);
  }

  &.options_right_aligned {
    right: 0;
    transform: translate(0, 10px);
  }

  &.options_center_aligned {
    left: 50%;
    transform: translate(-50%, 10px);
  }

  .option {
    white-space: nowrap;
    line-height: 32px;
    padding: 0 8px;
    cursor: pointer;
    color: var(--t-m);
    transition: 0.2s;
    border-radius: 4px;

    &:hover {
      color: var(--t);
      background: var(--b-m);
    }
  }

  .option--left-aligned {
    text-align: left;
  }

  .option--center-aligned {
    text-align: center;
  }

  .option--right-aligned {
    text-align: right;
  }

  .option--selected {
    cursor: auto;
    color: var(--t);
    font-weight: 700;

    &:hover {
      color: var(--t-p);
    }
  }
}

// select input
.select_input_wrapper {
  flex: 1 1 0;
  position: relative;
}

.select_input {
  border: none;
  outline: none;
  font-size: inherit;
  padding: 0;
  width: 100%;
  background: none;
  color: var(--t);
}

.display_cover {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.display_as_placeholder {
  opacity: 0.3;
}

.options_mask,
.options_header {
  display: none;
}

@media @mobile-screen {
  .select {
    .options {
      max-height: 25vh;
    }
  }

  .dropdown_select {
    .display_large,
    .display_mid {
      .text_wrapper {
        font-size: 14px;
        line-height: 32px;
      }

      .icon {
        font-size: 18px;
        margin-left: 10px;
      }
    }
  }

  .dropdown_options {
    pointer-events: all;

    &.options {
      position: fixed;
      display: flex;
      flex-direction: column;
      left: 0;
      right: 0;
      z-index: @options-z-index;
      top: 0;
      bottom: 0;
      padding: 0;
      background: none;
      box-shadow: none;
      transform: none;
    }

    .options_mask,
    .options_header {
      display: block;
    }

    .options_mask {
      flex: 1 1 0;
      background: var(--m-60);
    }

    @h: 50px;
    @p: 20px;
    .options_header {
      position: relative;
      text-align: center;
      flex: 0 0 @h;
      line-height: @h;
      color: var(--t);
      padding: 0 @p;
      border-bottom: 1px solid var(--e);
      background: var(--b);
    }

    .options_title {
      font-size: 14px;
      font-weight: 700;
    }

    .options_content {
      overflow: auto;
      flex: 0 0 40vh;
      padding: 16px;
      background: var(--b);
      box-shadow: 0 2px 7px var(--m-60);
    }

    .options_close_btn {
      position: absolute;
      display: block;
      width: 20px;
      height: @h;
      left: @p;
      top: 0;
      font-size: 20px;
      color: var(--t-m);
    }

    .option--left-aligned,
    .option--right-aligned,
    .option--center-aligned {
      text-align: center;
    }
  }
}
