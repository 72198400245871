// font files

@lighter: url("Metropolis-ExtraLight.woff2") format("woff2"),
  url("Metropolis-ExtraLight.woff") format("woff");
@normal: url("Metropolis-Light.woff2") format("woff2"),
  url("Metropolis-Light.woff") format("woff");
@bold: url("Metropolis-SemiBold.woff2") format("woff2"),
  url("Metropolis-SemiBold.woff") format("woff");
@bolder: url("Metropolis-ExtraBold.woff2") format("woff2"),
  url("Metropolis-ExtraBold.woff") format("woff");

// for font-weight
// use only
// 300 / 500 / 700 / 900

@font-face {
  font-family: "main";
  font-weight: 200;
  src: @lighter;
}

@font-face {
  font-family: "main";
  font-weight: 300;
  src: @lighter;
}

@font-face {
  font-family: "main";
  font-weight: 400;
  src: @normal;
}

@font-face {
  font-family: "main";
  font-weight: 500;
  src: @normal;
}

@font-face {
  font-family: "main";
  font-weight: 600;
  src: @normal;
}

@font-face {
  font-family: "main";
  font-weight: 700;
  src: @bold;
}

@font-face {
  font-family: "main";
  font-weight: 800;
  src: @bolder;
}

@font-face {
  font-family: "main";
  font-weight: 900;
  src: @bolder;
}
