@import "./shared-variables.module";

// variables
@link-color: silver;
@desk-header-height: @desk-header-height-in-px * 1px;
@desk-header-height-taller: @desk-header-height-taller-in-px * 1px;
@mobile-header-height: @mobile-header-height-in-px * 1px;
@fold-header-height: @fold-header-height-in-px * 1px;

.doc-content {
  // content width
  width: 92vw;
  max-width: 1680px;
  margin-left: auto;
  margin-right: auto;
}

.ellipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.multi-line-text {
  white-space: pre-wrap;
  word-break: break-word;
  hyphens: auto;
  -webkit-hyphens: auto;
}

@lg-screen-min-width: @lg-screen-min-width-in-px * 1px;
@sm-screen-max-width: @sm-screen-max-width-in-px * 1px;
@xs-screen-max-width: @xs-screen-max-width-in-px * 1px;
@mobile-screen-max-width: @mobile-screen-max-width-in-px * 1px;
@fold-screen-max-width: @fold-screen-max-width-in-px * 1px;

// media
@mobile-screen-max-width-in-px-plus-one: @mobile-screen-max-width-in-px + 1;

@sm-screen: ~"screen and (max-width: @{sm-screen-max-width-in-px}px)";
@xs-screen: ~"screen and (max-width: @{xs-screen-max-width-in-px}px)";
@mobile-screen: ~"screen and (max-width: @{mobile-screen-max-width-in-px}px)";
@fold-screen: ~"screen and (max-width: @{fold-screen-max-width-in-px}px)";
@non-mobile-screen: ~"screen and (min-width: @{mobile-screen-max-width-in-px-plus-one}px)";
@lg-screen: ~"screen and (min-width: @{lg-screen-min-width-in-px}px)";
@dark-mode: ~"(prefers-color-scheme: dark)";
@touch-screen: ~"(pointer: coarse)";

// form
@form-border-radius: 4px;
@form-line-height: 32px; // CHECK: 32px -> 40px

// form widths
@form-width: 200px;
@form-width-less: 120px;
@form-width-more: 320px;
@form-width-evenmore: 350px;

// form gap
@form-button-col-gap: 14px;

// s3 link prefix
@s3-prefix: "https://reversible-images-prod.s3.us-west-2.amazonaws.com/website";

.grid-list(@n, @col-gap, @row-gap: @col-gap) {
  display: grid;
  grid-template-columns: repeat(@n, minmax(0, 1fr));
  gap: @row-gap @col-gap;
}
