.chart-tooltip {
  [data-field] {
    font-family: "main", sans-serif;
    color: inherit;
  }

  [data-field="price"] {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
  }

  [data-field="size"],
  [data-field="time"] {
    font-size: 10px;
    line-height: 16px;
  }

  [data-field="icon"] {
    cursor: pointer;
    vertical-align: -0.166666em;
  }

  [data-field="icon"],
  [data-field="verified"] {
    height: 1em;
    line-height: 1em;
    font-size: 1em;
  }

  [data-field="user"] {
    display: flex;
    align-items: center;
    margin: 4px 0;
  }

  [data-field="username"] {
    font-size: 12px;
    font-weight: 700;
    margin: 0 2px 0 4px;
  }

  [data-field="avatar"] {
    width: 18px;
    height: 18px;
    border-radius: 9px;
  }
}
