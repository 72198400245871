@import "@/style/base";

@line-height: 20px;

.textarea {
  resize: none;
  box-sizing: border-box;
  line-height: @line-height;
  width: 100%;
  font-size: 14px;
  font-family: inherit;
  outline: none;
  padding: (@form-line-height - @line-height) / 2 10px;
  border: 1px solid var(--e-p2);
  border-radius: @form-border-radius;
  transition: border-color 0.2s ease;
  background: var(--b-p);
  color: var(--t);

  &::-webkit-input-placeholder {
    color: var(--t-m2);
  }

  &:focus {
    border-color: var(--t-p);
    outline: 2px solid var(--e);
  }
}

.current_length {
  color: var(--t-sm);
  margin-top: 6px;
  font-size: 14px;
  text-transform: lowercase;
}
