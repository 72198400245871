@import "@/style/base";

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  line-height: 36px;
  font-size: 18px;
  text-align: center;
  color: var(--t-m2);
}

.empty_cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@media @xs-screen {
  .empty {
    font-size: 16px;
  }
}
