.wrapper {
  position: relative;
  z-index: 0;
}

.wrapper_disabled {
  opacity: 0.3;
  cursor: not-allowed;

  > * {
    pointer-events: none;
  }
}

.login_button {
  width: 100%;
}
