.tag {
  @h: 14px;
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  background: var(--b-sm);
  border-radius: 2px;
  color: var(--b);
  padding: 0 4px;
  height: @h;
  font-weight: 500;
}
