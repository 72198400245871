@import "@/style/base";

.dialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: all;
}

.mask {
  background: var(--m-60);
  overflow: auto;
}

.modal {
  position: relative;
  z-index: 1;
  background: var(--b);
  box-shadow: 0 2px 7px var(--m-20);
  padding: 4px 20px 0;
  border-radius: @form-border-radius;
  margin: 10vh auto;
  box-sizing: border-box;
  max-width: 100vw;
}

.modal_title {
  line-height: 50px;
  border-bottom: 1px solid var(--e);
  color: var(--t);
  font-size: 18px;
  font-weight: 700;
}

.modal_content {
  padding: 20px 0;
}

@close-btn-padding: 15px;

.close_btn {
  color: var(--t);
  right: 0;
  top: 0;
  position: absolute;
  font-size: 24px;
  cursor: pointer;
  border-radius: 50%;
  padding: @close-btn-padding;

  &:hover {
    color: var(--t-p);
    transition: color 0.3s ease;
  }
}

.modal_footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}

.modal_button {
  width: 80px;
  margin-left: 16px;

  &:first-of-type {
    margin-left: 0;
  }
}

.confirm_modal {
  width: 400px;
}

.confirm_title {
  display: flex;
  align-items: center;
}

@media @mobile-screen {
  .modal_title {
    font-size: 16px;
  }

  .modal {
    padding: 4px 16px 0;
  }
}
