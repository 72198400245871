@import "@/style/base";
// stylelint-disable

.message {
  display: grid;
  padding-bottom: 5px;

  &:hover {
    .time {
      display: block;
    }
  }
}

.message_send {
  grid-template: ". time" 16px ". content" auto e("/") 1fr 1fr;
  text-align: right;

  .content_wrapper {
    flex-direction: row-reverse;
  }

  .content_box {
    background: var(--e);
    border-color: var(--e);
  }
}

.message_receive {
  grid-template: ". time ." 16px "avatar content ." auto e("/") 32px 1fr 50%;

  .content_box {
    background: var(--b);
    border-color: var(--e-p);
  }
}

// avatar display
.message_receive:first-of-type,
*:not(.message_receive) + .message_receive {
  .avatar {
    display: block;
  }
}

.avatar {
  grid-area: avatar;
  display: none;
}

.avatar_img {
  @size: 24px;
  width: @size;
  height: @size;
}

.time {
  display: none;
  align-self: end;
  grid-area: time;
}

.time,
.sys_time {
  color: var(--t-m2);
  font-size: 12px;
  padding-bottom: 2px;
}

.content_wrapper {
  display: flex;
  grid-area: content;
  align-items: flex-start;
  max-width: 700px;
}

.content {
  flex: 0 1 auto;
}

.content_full_width {
  flex-grow: 1;
}

.operators {
  flex: 0 0 32px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content_box {
  display: inline-block;
  border-width: 1px;
  border-style: solid;
  text-align: left;
}

.text_content_box {
  border-radius: 16px;
  padding: 12px;
  font-size: 14px;
  color: var(--t);
  line-height: 18px;
}

.link_in_text {
  color: var(--t-m2);
  cursor: pointer;
  font-style: italic;
}

.link_in_text.link_in_text {
  text-decoration: underline;
}

@img-content-padding: 10px;
.img_content_box {
  padding: @img-content-padding;
  border-radius: 6px;
  min-width: 60px;
}

.img {
  max-width: 100%;
}

.loading_icon,
.reload_icon {
  font-size: 24px;
}

.loading_icon {
  color: var(--e-p);
}

.reload_icon {
  color: var(--red);
  cursor: pointer;
}

// sys message
.sys_time {
  visibility: hidden;
}

.sys_message {
  text-align: center;
  margin: 30px auto;

  &:hover {
    .sys_time {
      visibility: visible;
    }
  }
}

.sys_content {
  display: inline-block;
  background: var(--b-m);
  border-radius: 15px;
  line-height: 20px;
  font-size: 14px;
  color: var(--t-m2);
  padding: 4px 20px;
  max-width: 50%;
}

.content_box_disabled {
  opacity: 0.5;
}

// card
.card {
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  font-size: 14px;
  color: var(--t-m2);
  border-radius: 8px;
}

.card_header {
  font-weight: 700;
  color: var(--t);
  font-size: 14px;
}

.card_content {
  color: var(--t-m);
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
}

.card_footer {
  display: flex;
  margin-top: 10px;
}

.card_btn {
  margin-right: @form-button-col-gap;

  &:last-of-type {
    margin-right: 0;
  }
}

.card_text_gray_out {
  flex: 1 1 auto;
  color: var(--t-m2);
  font-size: 14px;
}

.card_link.card_link {
  text-decoration: underline;
}

// special elements
.offer_price {
  display: inline;
  color: var(--t-m);
  font-size: 14px;
  font-weight: 700;
}

.offer_price_original_wrapper {
  color: var(--t-m2);
  margin-left: 6px;
}

.offer_price_original {
  display: inline;
}

.dt,
.dd {
  float: left;
  padding: 0;
  margin: 0;
  line-height: 20px;
}

@dt-width: 100px;

.dt {
  width: @dt-width;
  color: var(--t-m2);
}

.dd {
  width: calc(100% - @dt-width);
}

.card_loading {
  font-size: 16px;
  color: var(--t-m2);
}

.offer_name {
  margin: 0 0.5em;
  color: var(--t-m);
}

.paragraph {
  margin-bottom: 1em;
  color: var(--t-m2);
}

.address_card.address_card {
  margin: 0 -10px;
  background: none;
}

.shipping {
  font-size: 12px;
  margin-top: 4px;
  color: var(--t-m);
}

@media @sm-screen {
  .message_send {
    grid-template: ". time" 16px ". content" auto e("/") 35% 1fr;
  }

  .message_receive {
    grid-template: ". time ." 16px "avatar content ." auto e("/") 32px 1fr 30%;
  }
}

@media @mobile-screen {
  .message_send {
    grid-template: ". time" 16px ". content" auto e("/") 40px 1fr;
  }

  .message_receive {
    grid-template: ". time ." 16px "avatar content ." auto e("/") 32px 1fr 10px;
  }
}
