@import "@/style/base";

.review {
  font-weight: 700;
}

.avatar_img {
  height: 100%;
  width: 100%;
}

.avatar {
  grid-area: avatar;
  align-self: center;
  position: relative;
}

.avatar_clickable {
  cursor: pointer;
}

.name {
  .ellipsis-text;
  flex: 0 1 auto;
}

.review,
.transaction {
  font-size: 12px;
  color: var(--t-m);
}

.review_icon {
  position: relative;
  color: var(--t-m);
  font-size: 16px;
}

.transaction {
  margin-left: 8px;
}

.review_row {
  display: flex;
  align-items: center;
  grid-area: review;
  align-self: start;
  margin-left: -2px; // bias
}

.user_brief {
  display: inline-grid;
  grid-template: "avatar name" 1fr "avatar review" auto e("/") 30px 1fr;
  text-align: left;
  margin-left: 1px;
}

.user_brief_small {
  @avatar-size: 30px;
  gap: 0 10px;

  .avatar {
    width: @avatar-size;
    height: @avatar-size;
  }

  .name {
    font-size: 12px;
  }

  .review_row {
    margin-top: 2px;
  }
}

.user_brief_mid {
  @avatar-size: 36px;
  grid-template: "avatar name" 1fr "avatar review" auto e("/") @avatar-size 1fr;
  // grid-template: 'avatar name' 14px 'avatar review' 15px e('/') @avatar-size 1fr;
  gap: 0 10px;

  .avatar {
    width: @avatar-size;
    height: @avatar-size;
  }

  .name {
    font-size: 14px;
    font-weight: 700;
  }

  .review_icon {
    font-size: 14px;
    top: -1px;
  }

  .review,
  .transaction {
    font-size: 14px;
  }

  .review_row {
    margin-top: 6px;
    height: 15px;
  }
}

.user_brief_large {
  @avatar-size: 48px;
  grid-template: "avatar name" 1fr "avatar review" auto e("/") @avatar-size 1fr;
  // grid-template: 'avatar name' 24px 'avatar review' 24px e('/') @avatar-size 1fr;
  gap: 0 14px;

  .avatar {
    width: @avatar-size;
    height: @avatar-size;
  }

  .name {
    font-size: 16px;
    display: inline;
  }

  .review_icon {
    font-size: 18px;
    top: -1px;
  }

  .review,
  .transaction {
    font-size: 14px;
  }

  .review_row {
    height: 24px;
    margin-top: 2px;
  }
}

.user_brief_light {
  .review_icon,
  .review,
  .transaction,
  .name {
    color: var(--b-s);
  }
}

.user_brief_dark {
  .review_icon,
  .review,
  .transaction,
  .name {
    color: var(--e-p2);
  }
}

.name_row {
  .ellipsis-text;
  display: flex;
  align-items: center;
}

.name_row_linkable:hover {
  text-decoration: underline;
}

.brief_verify_icon {
  margin-left: 2px;
  font-size: 14px;
  font-weight: 500;
}

.story_ring {
  position: absolute;
}

@media @xs-screen {
  .name_row_linkable:hover {
    text-decoration: none;
  }
}

@media @mobile-screen {
  .transaction.transaction {
    margin-left: 2px;
  }
}
