@import "@/style/base";

.price {
  line-height: @form-line-height;
  font-size: 18px;
}

.trans_price {
  color: var(--t-m);
  margin-left: 14px;
}

.footer {
  margin-top: 20px;
}

.success_icon {
  color: var(--t);
  font-size: 24px;
  margin-right: 6px;
  position: relative;
  top: 2px;
}
