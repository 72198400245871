@import "@/style/base";

.input_box_disabled,
.input_wrapper_disabled {
  cursor: not-allowed;

  * {
    pointer-events: none;
  }

  > * {
    opacity: 0.5;
  }
}

.input_box,
.input_wrapper {
  line-height: @form-line-height;
  width: 100%;
  font-size: 16px;
  background: var(--b-p);
  transition: border-color 140ms ease;

  &.input_box_focused {
    border-color: var(--t-p);
    outline: 3px solid var(--b);
  }

  &.input_box_disabled,
  &.input_wrapper_disabled {
    background: var(--e-m);
  }
}

.input_box {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 4px 0 10px;
  border: 1px solid var(--e-p2);
  border-radius: @form-border-radius;
  height: @form-line-height + 2 * 1px;

  input {
    line-height: @form-line-height - 4px;
  }
}

.input_wrapper {
  position: relative;
  border-radius: @form-border-radius;

  input {
    box-sizing: border-box;
    border: 1px solid var(--e-p2);
    width: 100%;
    outline: none;
    padding: 0 4px 0 10px;
    line-height: @form-line-height;
    border-radius: @form-border-radius;
    font-size: inherit;
    background: none;
    color: var(--t);

    &::-webkit-input-placeholder {
      color: var(--t-m2);
    }

    &:disabled {
      color: inherit;
    }

    &::-ms-reveal,
    &::-ms-clear {
      display: none;
    }
  }

  &:focus-within input {
    border-color: var(--t-p);
    outline: 3px solid var(--e);
  }
}

.icon_link {
  font-size: 18px;
  color: var(--t-m2);
  transition: 0.3s ease;

  .hover_icon {
    display: none;
  }
}

.icon_link_interactable {
  cursor: pointer;

  &:hover {
    color: var(--t-m);
  }
}

.icon_link:hover {
  .hover_icon {
    display: unset;
  }

  .unhover_icon {
    display: none;
  }
}
