@import "@/style/base";

@media @touch-screen {
  .container_following {
    transition: 0s;
  }

  .container_transition {
    transition: 0.4s ease-out;
  }
}
