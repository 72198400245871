@import "@/style/base";

.toast_track {
  position: fixed;
  top: 10vh;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: @toast-z-index;
}

.toast_row {
  margin: 20px 100px 0;
  text-align: center;
}

.toast {
  display: inline-block;
  text-align: left;
  padding: 18px 20px;
  min-width: 200px;
  border-radius: 4px;
  color: var(--l-p);
  background: var(--d);
  pointer-events: all;
  box-shadow: 0 3px 7px var(--m-45);
  line-height: 22px;
  font-size: 16px;
}

.toast_with_icon {
  display: flex;
}

.toast_icon {
  font-size: 22px;
  margin-right: 10px;
  color: var(--l-p);
}

@media @mobile-screen {
  .toast_row {
    margin: 20px 18px;
  }
}
