@import "@/style/base";

@size: 100px;
@gap: 15px;

.image_select {
  padding-top: 8px;
}

.box {
  display: inline-block;
  height: @size;
  width: @size;
  margin: 0 @gap @gap 0;
  background: var(--b-m);
  vertical-align: middle;
  overflow: visible;
}

.box--uploadable {
  font-size: 40px;
  color: var(--e-p2);
  cursor: pointer;
  transition: 0.3s ease;

  &:hover {
    background: var(--e);
  }
}

.box--uploaded {
  position: relative;

  &::before {
    content: "";
    z-index: 1;
    position: absolute;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--b-s);
    pointer-events: none;
    transition: 0.2s ease;
  }
}

.overlays {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  justify-content: space-between;
  z-index: 2;
  flex-direction: row-reverse;
}

.btn {
  display: flex;
  @size: 28px;
  align-items: center;
  justify-content: center;
  width: @size;
  height: @size;
  border-radius: 50%;
  color: var(--b);
}

.icon {
  font-size: 24px;
}

.box--uploaded:hover {
  &::before {
    opacity: 0.2;
  }

  .overlays {
    display: flex;
  }
}

.box__dragging {
  opacity: 0;
}

.img {
  pointer-events: none; // the images are eventless
}

.img_wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.drop_area {
  position: absolute;
  width: 50% + @gap / 2;
  top: 0;
  bottom: 0;
  z-index: 1;
  visibility: hidden;
}

@bar-width: 4px;
.drop_area_droppable {
  visibility: visible;

  &::after {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    width: @bar-width;
    z-index: 1;
  }
}

.drop_area_pre {
  left: -@bar-width;

  &::after {
    left: 0;
  }
}

.drop_area_post {
  right: -@bar-width;

  &::after {
    right: 0;
  }
}

.drop_area_over::after {
  background: var(--b-s);
}

.current_length {
  color: var(--t-sm);
  font-size: 14px;
  text-transform: lowercase;
}

@media @mobile-screen {
  .box {
    @size: 100px;
    width: @size;
    height: @size;
    margin: 10px 20px 20px 0;
  }
  .overlays {
    display: flex;
    top: -14px;
    right: -10px;
  }

  .box--uploadable:hover {
    background: var(--b-m);
  }

  .box--uploaded:hover::before {
    opacity: 0;
  }

  .btn {
    background: var(--t-m2-50);
  }
}
