@import "@/style/base";

.major {
  position: relative;
  width: 100%;
  padding-bottom: 75%; // aspect-ratio of 4 / 3
  height: 0;
  margin-bottom: 20px;
  overflow: hidden;
}

.wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.major,
.thumbnail {
  background: var(--b-m);
}

.pic {
  width: 100%;
  height: 100%;
}

.footer {
  display: flex;
  align-items: stretch;
  overflow-x: auto;
  overflow-y: visible;
  @m: 14px;
  margin-bottom: @m;
  padding-bottom: @m;
}

.thumbnail {
  box-sizing: border-box;
  min-height: 120px;
  margin-right: 10px;
  flex: 0 0 120px;
  cursor: pointer;
  border: 3px solid transparent;
  transition: 0.3s ease;
}

.thumbnail--current {
  border-color: var(--b-s);
  background: var(--m-45);
}

.thumbnail_img {
  height: 100%;
}

.btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  @size: 60px;
  height: 100px;
  width: 60px;
  border-radius: 4px;
  background: var(--b-50);
  font-size: 32px;
  color: var(--t);
  cursor: pointer;
  transition: 0.2s ease;
  opacity: 0.5;

  &:hover {
    opacity: 1;
    font-size: 33px;
  }
}

.btn--left {
  left: 20px;
}

.btn--right {
  right: 20px;
}

.dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 8px;
  display: none;
  margin: auto;
  height: 14px;
  align-items: center;
  justify-content: center;
}

@media @sm-screen {
  .major {
    padding-bottom: 100%;
  }

  .thumbnail {
    flex-basis: 80px;
    min-height: 80px;
  }

  .btn--left {
    left: 0;
  }

  .btn--right {
    right: 0;
  }
}

.container {
  transform: translateZ(0);
}

@media @xs-screen {
  .major {
    padding-bottom: 120%;
  }
}

@media @mobile-screen {
  .btn {
    display: none;
  }

  .major {
    background: none;
    margin: 10px 0;
  }

  .dots {
    display: flex;
  }

  .footer {
    display: none;
  }
}
