@import "@/style/animation.less";

.icon {
  display: inline-block;
  height: 1em;
  line-height: 1em;
  font-size: 1em;
  vertical-align: -0.166666em;

  // font-size: 22 / 32em; // The Standard inline text icon
  // vertical-align: middle;
}

.icon_spinning {
  animation: spin 1s linear infinite;
}
