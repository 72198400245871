@import "@/style/base";

.card_row_selectable {
  cursor: pointer;
  padding-right: 10px;
}

.card_row {
  display: flex;
  padding-top: 14px;
  padding-bottom: 14px;
  border-bottom: 1px solid var(--e);
  align-items: center;

  &:last-of-type {
    border-bottom: none;
  }

  &:first-of-type:not(.card_row_selectable) {
    padding-top: 0;
  }
}

.card_row_selected {
  background: var(--b-m);
}

.card_wrapper {
  flex: 1 1 auto;
}

.radio {
  margin: 0 15px;
}

.radio_hidden {
  visibility: hidden;
}

.card_row_disabled {
  cursor: not-allowed;
}

.footer {
  display: flex;
  margin-top: 14px;
}

.btn {
  margin-left: @form-button-col-gap;

  &:first-of-type {
    margin-left: 0;
  }
}

@media @mobile-screen {
  .card_row_selectable {
    padding-right: 4px;
  }

  .radio {
    margin: 0 0 0 10px;
  }
}
