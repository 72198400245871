@import "@/style/base";

.action_sheet {
  background: var(--b);
  box-shadow: 0 2px 10px var(--m-25);
  border-radius: 4px;
  padding: 6px 0;
  width: 180px;
}

.header {
  padding: 6px 14px 10px;
  border-bottom: 1px solid var(--e);
  font-weight: 700;
  font-size: 14px;
}

.footer {
  border-top: 1px solid var(--e);
}

.action_button {
  display: block;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  line-height: 40px;
  padding: 0 14px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background: var(--b-m);
  }
}

.action_button_danger {
  color: red;
}

.action_button_primary {
  font-weight: 700;
}

.action_sheet_left,
.action_sheet_left .action_button {
  text-align: left;
}

.action_sheet_center,
.action_sheet_center .action_button {
  text-align: center;
}

.mask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--m-60);
}

@media @mobile-screen {
  .action_sheet {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 0;
    border-radius: 0;
    width: 100%;
    min-height: 60px;
  }

  .action_button {
    line-height: 48px;
    font-size: 16px;
  }

  .footer {
    padding: 8px 0;
  }

  .header {
    padding: 20px 14px 20px;
    font-size: 16px;
    // border-bottom: none;
  }
}

@media @touch-screen {
  .action_button {
    &:hover {
      background: inherit;
    }

    &:active {
      background: var(--b-m);
    }
  }
}
